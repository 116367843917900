import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import taskManagerService from "../../@core/auth/jwt/useTaskManager";

const initialState = {
    board: null,
    loading: true
}

export const fetchBoard = createAsyncThunk('board/fetchBoard', async (args) => {
    const response = await taskManagerService.getBoard(args.organizationId);
    return response.data
})

const boardSlice = createSlice({
    name: 'board',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchBoard.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchBoard.fulfilled, (state, action) => {
                state.loading = false;
                state.board = action.payload;
            })
            .addCase(fetchBoard.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message
            })
    }
})

export const {} = boardSlice.actions

export default boardSlice.reducer

export const selectBoard = state => state.board.board

