import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import taskManagerService from "../../@core/auth/jwt/useTaskManager";

const initialState = {
    task: null,
    status: 'idle',
    error: null
}

export const fetchTask = createAsyncThunk('task/fetchTask', async (args) => {
    const response = await taskManagerService.getTask(args.organizationId, args.taskId);
    return response.data.task
})

const taskSlice = createSlice({
    name: 'task',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchTask.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchTask.fulfilled, (state, action) => {
                if (state.status === 'loading') {
                    state.status = 'idle'
                    state.task = action.payload;
                }
            })
            .addCase(fetchTask.rejected, (state, action) => {
                state.status = 'idle'
                state.error = action.error.message
            })
    }
})

export const {} = taskSlice.actions

export default taskSlice.reducer

export const selectTask = state => state.task.task
