import axios from 'axios'
import jwtDefaultConfig from './jwtDefaultConfig'
import { fetchOperatorLanguage } from '../../../redux/fetchOperatorLanguage'

export default class TaskManagerService {
    httpClient = null
    jwtConfig = { ...jwtDefaultConfig }
    constructor(jwtOverrideConfig) {
        this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }
        this.httpClient = axios.create({
            baseURL: process.env.REACT_APP_TASK_MANAGER_API_URL,
            params: {
                _locale: fetchOperatorLanguage()
            }
        })

        // ** Request Interceptor
        this.httpClient.interceptors.request.use(
            (config) => {
                let accessToken = this.getToken()
                if (accessToken && accessToken !== 'undefined') {
                    // ** eslint-disable-next-line no-param-reassign
                    accessToken = JSON.parse(accessToken)
                    config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
                }
                return config
            },
            (error) => Promise.reject(error)
        )

        this.httpClient.interceptors.response.use(
            (response) => response,
            (error) => {
                const { response } = error
                if (response && response.status === 401) {
                    localStorage.removeItem('userData')
                    localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
                    if (window.location.pathname !== '/login') {
                        window.location.href = '/login'
                    }
                }
                return Promise.reject(error)
            }
        )
    }

    getToken() {
        return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
    }

    async getBoard(organizationId) {
        return this.httpClient.get(`/api/organization/${organizationId}/projects/find`)
    }

    async createBoardDemo(organizationId, boardId) {
        return this.httpClient.post(`/api/organization/${organizationId}/project/${boardId}/demo`)
    }

    async createList(organizationId, boardId, name) {
        return this.httpClient.post(`/api/organization/${organizationId}/projects/${boardId}/lists`, { name })
    }

    async deleteList(organizationId, listId) {
        return this.httpClient.delete(`/api/organization/${organizationId}/lists/${listId}`)
    }

    async changeListName(organizationId, listId, name) {
        return this.httpClient.patch(`api/organization/${organizationId}/lists/${listId}`, { name })
    }

    async createTask(organizationId, ...args) {
        return this.httpClient.post(`/api/organization/${organizationId}/tasks`, ...args)
    }

    async deleteTask(organizationId, taskId) {
        return this.httpClient.patch(`/api/organization/${organizationId}/tasks/${taskId}/archive`)
    }

    async moveTaskToList(organizationId, taskId, ...args) {
        return this.httpClient.patch(`/api/organization/${organizationId}/tasks/${taskId}/move`, ...args)
    }

    async sortTaskOnList(organizationId, taskId, ...args) {
        return this.httpClient.patch(`/api/organization/${organizationId}/tasks/${taskId}/sort`, ...args)
    }

    async changeTaskNameAndDescription(organizationId, taskId, ...args) {
        return this.httpClient.patch(`/api/organization/${organizationId}/tasks/${taskId}`, ...args)
    }

    async createTaskComment(organizationId, taskId, ...args) {
        return this.httpClient.post(`/api/organization/${organizationId}/tasks/${taskId}/comments`, ...args)
    }

    async deleteTaskComment(organizationId, commentId) {
        return this.httpClient.delete(`/api/organization/${organizationId}/comments/${commentId}`)
    }

    async createTaskDueDate(organizationId, taskId, ...args) {
        return this.httpClient.patch(`/api/organization/${organizationId}/tasks/${taskId}/due-date`, ...args)
    }

    async uploadTaskAttachment(formData) {
        return this.httpClient.post('/api/file', formData)
    }

    async assignAttachmentToTask(organizationId, taskId, ...args) {
        return this.httpClient.post(`/api/organization/${organizationId}/tasks/${taskId}/attachments`, ...args)
    }

    async deleteTaskAttachment(organizationId, taskId, attachmentId) {
        return this.httpClient.delete(`/api/organization/${organizationId}/tasks/${taskId}/attachments/${attachmentId}`)
    }

    async assignUserToTask(organizationId, taskId, ...args) {
        return this.httpClient.patch(`/api/organization/${organizationId}/tasks/${taskId}/assign`, ...args)
    }

    async createTaskLabel(organizationId, ...args) {
        return this.httpClient.post(`/api/organization/${organizationId}/labels`, ...args)
    }

    async getLabels(organizationId) {
        return this.httpClient.get(`/api/organization/${organizationId}/labels`)
    }

    async deleteTaskLabel(organizationId, labelId) {
        return this.httpClient.delete(`/api/organization/${organizationId}/labels/${labelId}`)
    }

    async updateTaskLabel(organizationId, labelId, ...args) {
        return this.httpClient.patch(`/api/organization/${organizationId}/labels/${labelId}`, ...args)
    }

    async assignTaskLabel(organizationId, taskId, ...args) {
        return this.httpClient.patch(`/api/organization/${organizationId}/tasks/${taskId}/label`, ...args)
    }

    async getTask(organizationId, taskId) {
        return this.httpClient.get(`api/organization/${organizationId}/tasks/${taskId}`)
    }

    async deleteTaskDueDate(organizationId, taskId) {
        return this.httpClient.patch(`/api/organization/${organizationId}/tasks/${taskId}/due-date`)
    }

    async createChecklistItem(organizationId, ...args) {
        return this.httpClient.post(`/api/organization/${organizationId}/checklist-item`, ...args)
    }

    async deleteChecklistItem(organizationId, checklistId) {
        return this.httpClient.delete(`/api/organization/${organizationId}/checklist-item/${checklistId}`)
    }

    async completeChecklistItem(organizationId, checklistId, ...args) {
        return this.httpClient.patch(`api/organization/${organizationId}/checklist-item/${checklistId}/complete`, ...args)
    }

    async updateChecklistItemName(organizationId, checklistId, ...args) {
        return this.httpClient.patch(`api/organization/${organizationId}/checklist-item/${checklistId}`, ...args)
    }
}
