// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import project from './project'
import table from './table'
import board from './taskManager/board'
import task from './taskManager/task'
import taskManager from './taskManager'
import subscriptions from './subscriptions'
import { combineReducers } from 'redux'

const appReducer = combineReducers({
    auth,
    project,
    table,
    navbar,
    layout,
    board,
    task,
    taskManager,
    subscriptions
})

const rootReducer = (state, action) => {
    if (action.type === 'DESTROY_SESSION') {
        state = undefined
        localStorage.clear()
    }

    return appReducer(state, action)
}

export default rootReducer
