import axios from 'axios'
import jwtDefaultConfig from './jwtDefaultConfig'
import { fetchOperatorLanguage } from '../../../redux/fetchOperatorLanguage'

export default class JwtService {
    httpClient = null

    // ** jwtConfig <= Will be used by this service
    jwtConfig = { ...jwtDefaultConfig }

    // ** For Refreshing Token
    isAlreadyFetchingAccessToken = false

    // ** For Refreshing Token
    subscribers = []

    constructor(jwtOverrideConfig) {
        this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

        this.httpClient = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            params: {
                _locale: fetchOperatorLanguage()
            }
        })

        // ** Request Interceptor
        this.httpClient.interceptors.request.use(
            (config) => {
                // ** Get token from localStorage
                let accessToken = this.getToken()
                // ** If token is present add it to request's Authorization Header
                if (accessToken && accessToken !== 'undefined') {
                    // ** eslint-disable-next-line no-param-reassign
                    accessToken = JSON.parse(accessToken)
                    config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
                }
                return config
            },
            (error) => Promise.reject(error)
        )

        // ** Add request/response interceptor
        this.httpClient.interceptors.response.use(
            (response) => response,
            (error) => {
                const { response } = error
                if (response && response.status === 401) {
                    localStorage.removeItem('userData')
                    localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
                    this.isAlreadyFetchingAccessToken = true
                    if (window.location.pathname !== '/login') {
                        window.location.href = '/login'
                    }
                }
                return Promise.reject(error)
            }
        )
    }

    getToken() {
        return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
    }

    setToken(value) {
        localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
    }

    async forgotPassword(email) {
        return this.httpClient.post('/api/user/forgot-password', { email })
    }

    async resetPassword(userId, code, newPassword) {
        return this.httpClient.post('/api/user/forgot-password/new-password', {
            userId,
            code,
            newPassword
        })
    }

    async login(...args) {
        return this.httpClient.post('/api/login', ...args)
    }

    register(...args) {
        return this.httpClient.post('/api/user/register/email', ...args)
    }

    async updateColumnName(documentId, tableId, columnId, name) {
        return this.httpClient.post(`/api/document/${documentId}/table/${tableId}/column/${columnId}/rename`, {
            name
        })
    }

    async getUser() {
        return this.httpClient.get('/api/user/me')
    }

    async getProjects() {
        return this.httpClient.get('/api/project')
    }

    async getProject(projectId) {
        return this.httpClient.get(`/api/project/${projectId}`)
    }

    async getProjectOperatorInvitations(projectId) {
        return this.httpClient.get(`/api/project/${projectId}/operator-invitations`)
    }

    async updateTableColumnConfiguration(documentId, tableId, columnId, configuration) {
        return this.httpClient.post(`/api/document/${documentId}/table/${tableId}/column/${columnId}/configuration`, {
            configuration
        })
    }

    async inviteeProjectOperatorByEmail(projectId, email) {
        return this.httpClient.post(`/api/project/${projectId}/operator/invitee`, {
            operatorEmail: email
        })
    }

    async cancelProjectOperatorInvitation(projectId, invitationId) {
        return this.httpClient.post(`/api/project/${projectId}/operator/invitation/${invitationId}/cancel`)
    }

    async changeProjectName(projectId, name) {
        return this.httpClient.post(`/api/project/${projectId}/rename`, { name })
    }

    async deleteProject(projectId) {
        return this.httpClient.delete(`/api/project/${projectId}`)
    }

    async createTable(documentId, ...args) {
        return this.httpClient.post(`/api/document/${documentId}/table`, ...args)
    }

    async updateCellValue(documentId, tableId, rowId, columnId, value) {
        return this.httpClient.post(`/api/document/${documentId}/table/${tableId}/cell`, {
            rowId,
            columnId,
            value
        })
    }

    async createTableRow(documentId, tableId, ...args) {
        return this.httpClient.post(`/api/document/${documentId}/table/${tableId}/row`, ...args)
    }

    async createTableColumn(documentId, tableId, ...args) {
        return this.httpClient.post(`/api/document/${documentId}/table/${tableId}/column`, ...args)
    }

    async deleteTableColumn(documentId, tableId, columnId) {
        return this.httpClient.delete(`/api/document/${documentId}/table/${tableId}/column/${columnId}`)
    }

    async deleteTableRow(documentId, tableId, rowId) {
        return this.httpClient.delete(`/api/document/${documentId}/table/${tableId}/row/${rowId}`)
    }

    async getTables(documentId) {
        return this.httpClient.get(`/api/document/${documentId}/table`)
    }

    async getTableRows(documentId, tableId) {
        return this.httpClient.get(`/api/document/${documentId}/table/${tableId}/rows`)
    }

    async getTableColumns(documentId, tableId) {
        return this.httpClient.get(`/api/document/${documentId}/table/${tableId}/columns`)
    }

    async createProject(...args) {
        return this.httpClient.post('/api/project', ...args)
    }

    async createProjectWorkspace(projectId, ...args) {
        return this.httpClient.post(`/api/project/${projectId}/workspace`, ...args)
    }

    async changeWorkspaceName(projectId, workspaceId, name) {
        return this.httpClient.post(`/api/project/${projectId}/workspace/${workspaceId}/rename`, { name })
    }

    async deleteProjectWorkspace(projectId, workspaceId) {
        return this.httpClient.delete(`/api/project/${projectId}/workspace/${workspaceId}`)
    }

    async deleteProjectMember(projectId, operatorId) {
        return this.httpClient.delete(`/api/project/${projectId}/operator/${operatorId}`)
    }

    async changePassword(newPassword) {
        return this.httpClient.post('/api/user/change-password', newPassword)
    }

    async changeEmail(email) {
        return this.httpClient.post('/api/user/email', email)
    }

    async changeUserName(name) {
        return this.httpClient.post('/api/user/name', name)
    }

    async deleteAccount(currentPassword) {
        return this.httpClient.post('/api/user/delete', currentPassword)
    }

    async updateColumnOrder(documentId, tableId, columnId, direction) {
        return this.httpClient.post(`/api/document/${documentId}/table/${tableId}/column/${columnId}/change-order`, {
            direction
        })
    }

    async updateRowOrder(documentId, tableId, firstRowId, secondRowId) {
        return this.httpClient.post(
            `/api/document/${documentId}/table/${tableId}/row/${firstRowId}/${secondRowId}/change-order`
        )
    }

    async deleteTable(documentId, tableId) {
        return this.httpClient.delete(`/api/document/${documentId}/table/${tableId}`)
    }

    async changeLanguage(projectId, locale) {
        return this.httpClient.post(`/api/project/${projectId}/change-language`, { locale })
    }

    async getSubscriptionPlans() {
        return this.httpClient.get('/api/stripe/prices')
    }

    async getSubscriptionHistory(projectId) {
        return this.httpClient.get(`/api/project/${projectId}/subscription/invoice`)
    }

    async getCurrentSubscription(projectId) {
        return this.httpClient.get(`/api/project/${projectId}/subscription`)
    }

    async createProjectSubscription(projectId, ...args) {
        return this.httpClient.post(`/api/project/${projectId}/subscription/subscribe`, ...args)
    }

    async changeProjectSubscription(projectId, ...args) {
        return this.httpClient.post(`/api/project/${projectId}/subscription/change`, ...args)
    }

    async cancelProjectSubscription(projectId) {
        return this.httpClient.post(`/api/project/${projectId}/subscription/cancel`)
    }
}
