// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

const initialCurrentWorkspace = () => {
    const item = window.localStorage.getItem('currentWorkspace')
    //** Parse stored json or if none return initialValue
    return item ? JSON.parse(item) : {}
}

export const projectSlice = createSlice({
    name: 'project',
    initialState: {
        projects: [],
        currentWorkspace: initialCurrentWorkspace()
    },
    reducers: {
        projectsReloaded: (state, action) => {
            state.projects = action.payload;
        },
        currentWorkspaceChanged: (state, action) => {
            state.currentWorkspace = action.payload;
            localStorage.setItem('currentWorkspace', JSON.stringify(state.currentWorkspace))
        }
    }
});

export const selectProjects = (state) => state.project.projects
export const selectCurrentWorkspace = (state) => state.project.currentWorkspace
export const selectCurrentProject = (state) => {
    if (state.project.currentWorkspace && state.project.currentWorkspace.projectId) {
        return state.project.projects.find(p => p.id === state.project.currentWorkspace.projectId);
    }

    return null;
}

export const { projectsReloaded, currentWorkspaceChanged } = projectSlice.actions

export default projectSlice.reducer
