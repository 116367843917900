import {createSlice} from "@reduxjs/toolkit";

const CURRENT_TABLE_FILTERS_STORAGE_KEY = 'currentTableFilters';
const CURRENT_TABLE_SORTERS_STORAGE_KEY = 'currentTableSorters';

const initialCurrentTableFilters = () => {
    const item = window.localStorage.getItem(CURRENT_TABLE_FILTERS_STORAGE_KEY)
    return item ? JSON.parse(item) : []
}

const initialCurrentTableSorters = () => {
    const item = window.localStorage.getItem(CURRENT_TABLE_SORTERS_STORAGE_KEY)
    return item ? JSON.parse(item) : []
}

export const tableSlice = createSlice({
    name: 'table',
    initialState: {
        table: {},
        currentTableFilters: initialCurrentTableFilters(),
        currentTableSorters: initialCurrentTableSorters()
    },
    reducers: {
        tableChanged: (state, action) => {
            state.table = action.payload;
        },
        tableColumnsChanged: (state, action) => {
            state.currentTableChange = action.payload;
        },
        tableSortersChanged: (state, action) => {
            state.currentTableSorters = action.payload;
            localStorage.setItem(CURRENT_TABLE_SORTERS_STORAGE_KEY, JSON.stringify(state.currentTableSorters))
        },
        tableFiltersChanged: (state, action) => {
            state.currentTableFilters = action.payload
            localStorage.setItem(CURRENT_TABLE_FILTERS_STORAGE_KEY, JSON.stringify(state.currentTableFilters))
        },
        tableFiltersAdded: (state, action) => {
            if (typeof state.currentTableFilters === 'undefined') {
                state.currentTableFilters = [];
            }

            state.currentTableFilters.push(action.payload);
        },
        tableFiltersRemoved: (state, action) => {
            if (typeof state.currentTableFilters === 'undefined') {
                return;
            }

            state.currentTableFilters = state.currentTableFilters.filter(filter => filter.id !== action.payload);
            localStorage.setItem(CURRENT_TABLE_FILTERS_STORAGE_KEY, JSON.stringify(state.currentTableFilters))
        },
        tableFilterChanged: (state, action) => {
            if (typeof state.currentTableFilters === 'undefined') {
                return;
            }

            state.currentTableFilters = state.currentTableFilters
                .map(filter => (filter.id === action.payload.id ? action.payload : filter));
            localStorage.setItem(CURRENT_TABLE_FILTERS_STORAGE_KEY, JSON.stringify(state.currentTableFilters))
        }
    }
})

export const selectCurrentTableInfo = (state) => state.table.table;
export const selectCurrentColumnsChanged = (state) => state.table.currentTableChange;
export const selectCurrentTableFilters = (state) => (state.table.currentTableFilters ? state.table.currentTableFilters : []);
export const selectCurrentTableSorters = (state) => (state.table.currentTableSorters ? state.table.currentTableSorters : []);

export const {
    tableChanged,
    tableColumnsChanged,
    tableSortersChanged,
    tableFiltersChanged,
    tableFiltersAdded,
    tableFiltersRemoved,
    tableFilterChanged
} = tableSlice.actions

export default tableSlice.reducer
