import { createSlice } from '@reduxjs/toolkit'

export const subscriptions = createSlice({
    name: 'subscriptions',
    initialState: {
        subscriptions: null,
        subscription: {},
        trialDaysState: undefined,
        operatorsExceeded: null
    },
    reducers: {
        isNotSubscribed: (state, action) => {
            state.subscriptions = action.payload
        },
        subscription: (state, action) => {
            state.subscription = action.payload
        },
        trialDaysState: (state, action) => {
            state.trialDaysState = action.payload
        },
        operatorsExceeded: (state, action) => {
            state.operatorsExceeded = action.payload
        }
    }
})

export const selectIsNotSubscribed = (state) => state.subscriptions.subscriptions
export const selectCurrentSubscription = (state) => state.subscriptions.subscription
export const selectCurrentTrialDaysState = (state) => state.subscriptions.trialDaysState
export const selectCurrentOperatorsExceeded = (state) => state.subscriptions.operatorsExceeded

export const { isNotSubscribed, subscription, trialDaysState, operatorsExceeded } = subscriptions.actions

export default subscriptions.reducer
