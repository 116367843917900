import {createSlice} from "@reduxjs/toolkit";

export const taskManagerSlice = createSlice({
    name: 'taskManager',
    initialState: {},
    reducers: {
        updateCurrentBoard: (state, action) => {
            state.currentBoard = action.payload;
        },
        handleSelectedTask: (state, action) => {
            state.selectedTaskId = action.payload;
        },
        updateProjectLabels: (state, action) => {
            state.projectLabels = action.payload;
        }
    }
})

export const selectCurrentBoard = (state) => state.taskManager.currentBoard;

export const selectCurrentTask = (state) => state.taskManager.selectedTaskId;
export const selectProjectLabels = (state) => state.taskManager.projectLabels;

export const {updateCurrentBoard, handleSelectedTask, updateProjectLabels} = taskManagerSlice.actions

export default taskManagerSlice.reducer
